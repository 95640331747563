.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  display: flex;
  max-width: 80rem;
  z-index: 1;
}

.content {
  background-color: #fff;
  flex: 1;
}

.devBorder {
  height: 30px;
  margin-top: 30px;
}
.devBorder::before {
  content: 'TESTMILJÖ';
  font-weight: bold;
  text-align: center;
  background-color: rgb(239, 247, 133);
  height: 30px;
  position: fixed;
  padding-top: 0.2em;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
