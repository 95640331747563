@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Montserrat/Montserrat-Regular.woff2) format('woff2'),
    url(/fonts/Montserrat/Montserrat-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Montserrat/Montserrat-Bold.woff2) format('woff2'),
    url(/fonts/Montserrat/Montserrat-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Montserrat/Montserrat-Bold.woff2) format('woff2'),
    url(/fonts/Montserrat/Montserrat-Bold.woff) format('woff');
}
/* yes 700 and 600 uses same font. Sue me! */
/* download more from google fonts if you (REALLY) need to */

.DayPicker {
  display: block !important;
}

.squareBlock__divider-line::after {
  background-image: linear-gradient(
    to right,
    #f27309 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 15px 4px;
  background-repeat: repeat-x;
  width: 40px;
  height: 4px;
  content: '';
  position: absolute;
  top: 50%;
  right: -40px;
}

@media screen and (max-width: 62em) {
  .squareBlock__divider-line::after {
    background-image: linear-gradient(
      to bottom,
      #f27309 70%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 4px 15px;
    background-repeat: repeat-y;
    width: 4px;
    height: 40px;
    right: 50%;
    top: 100%;
  }
}

.squareBlock__icon-wrapper--white > div {
  filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2%)
    hue-rotate(194deg) brightness(105%) contrast(100%);
}

.FAQSquareBlock__answer > p {
  margin-bottom: 1em;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
